* {
  box-sizing: border-box;
}

html {
  --bg: white;
  --fg: #1e1e1e;
  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: rgb(90, 206, 132);
  --primary: #ff585d;
  --secondary: #0072ce;

  background-color: var(--bg);
  color: var(--fg);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.3;
}

body {
  margin: 0;
  background: #001220;
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;
}

main {
  margin: 0 auto;
}

input {
  margin: 0;
  background-color: transparent;
  display: block;
  width: 100%;
  padding: 0;
  border-width: 0;
}

input:focus {
  outline: none;
  border-width: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[disabled] {
  color: black;
  opacity: 1 !important;
}
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.gray {
  color: #bdbdbd;
}

.bg-black {
  background-color: #2d3231;
}

.bg-mobile-nav {
  background-color: #001220;
}

.bg-mobile-nav-item {
  background-color: #000b19;
}

.color-black {
  color: #2d3231;
}

.user .account-name {
  max-width: 150px;
}

.balances .token:first-child {
  padding-top: 0;
}

.balances .token:last-child {
  padding-bottom: 0;
}

#pool-container::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}

/* Adboard */
.tooltip-content::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* This will position the arrow at the top of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgba(34, 36, 51, 1) transparent; /* This will make the top border black */
}

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: block;
  margin-left: 29px;
}

.drawboard {
  width: 600px;
  height: 600px;
}

.bucket {
  margin-bottom: 8px;
}

.active-bucket {
  outline: 5px auto !important;
  -moz-outline-radius: 9999px;
}

.compact-picker {
  width: 260px !important;
}

.flexbox-fix {
  display: none !important;
}

.blur {
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.9);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
}

.tooltip {
  visibility: invisible;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 50;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thum {
  background: transparent;
}

.status-bar {
  font-size: 14px;
  line-height: 32px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 2em;
  -webkit-transform-origin: left bottom;
  -moz-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: translate(29.29%, -100%) rotate(45deg);
  -moz-transform: translate(29.29%, -100%) rotate(45deg);
  transform: translate(29.29%, -100%) rotate(45deg);
  text-indent: 0;
}

.ended {
  color: #fff;
  background: #bbb;
}

.pending {
  color: #fff;
  background: #00515a;
}
